<template>
  <div class="project-reports">
    <div class="container fluid">
      <!-- ===================== Sales Type Selector ======================= -->
      <div class="card flat p-1 py-2 sm-p-3 mb-2 row justify-content-center">
        <h2 class="col-12 p-2 mb-4">Project Reports</h2>
        <div class="col-12 sm-col-6">
          <selector
            class="w-100"
            :value="selectedReport"
            :options="reportType"
            :optionLabel="(val) => val.label"
            :optionValue="(val) => val.value"
            @input="changeReportType"
          ></selector>
        </div>
      </div>

      <div class="card flat p-2">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Selector: () => import("@/components/GlobalComponents/Selector")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      reportType: [
        {
          label: "Sales",
          value: "ProjectSalesReport"
        },
        {
          label: "Aging",
          value: "ProjectAgingReport"
        }
      ]
    };
  },
  computed: {
    selectedReport() {
      switch (this.$route.name) {
        case "ProjectSalesReport":
          return "ProjectSalesReport";
        case "ProjectAgingReport":
          return "ProjectAgingReport";
        default:
          return "";
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    changeReportType(routeName) {
      this.$router.replace({
        name: routeName,
        params: { projectId: this.$route.params.projectId }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
